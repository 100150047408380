// Requirements
import 'jquery';

(function($) {
    'use strict';

    //console.log('Document Loaded');
    // console.log(() => {}); // To test Babel converting ES6 to ES5

    jQuery(document).ready(function($) {

      // Dropdown Menu
      if( !Modernizr.touch && window.innerWidth >= 1200 ) {
        $('.navbar-nav .dropdown, .navbar-nav .dropdown-submenu').hover(function() {
          $(this).addClass('open').animateNav({top: 10, fade: 'in'});
        }, function() {
          $(this).removeClass('open').animateNav({fade: 'out'});
        });
      }

      // Bar
      $('#page').scroll(function() {
        // calculate the percentage the user has scrolled down the page
        var scrollPercent = 100 * (($('#content').offset().top - 60) * -1) / ($('#content').height() - $('#page').height());
        $('.bar-long>span>i').css('height', scrollPercent +"%"  );
        // console.log(scrollPercent);
      });

      if(false) $(window).scroll(function() {
        // calculate the percentage the user has scrolled down the page
        var scrollPercent = 100 * $(window).scrollTop() / ($(document).height() - $(window).height());
        $('.bar-long').css('width', scrollPercent +"%"  );
        //console.log(scrollPercent);
      });

      // Datepicker
      $.fn.datepicker.dates['hr'] = {
        days: ["Nedjelja", "Ponedjeljak", "Utorak", "Srijeda", "Četrtak", "Petak", "Subota"],
        daysShort: ["Ned", "Pon", "Uto", "Sri", "Čet", "Pet", "Sub"],
        daysMin: ["Ne", "Po", "Ut", "Sr", "Če", "Pe", "Su"],
        months: ["Siječanj", "Veljača", "Ožujak", "Travanj", "Svibanj", "Lipanj", "Srpanj", "Kolovoz", "Rujan", "Listopad", "Studeni", "Prosinac"],
        monthsShort: ["Sij", "Velj", "Ožu", "Tra", "Svi", "Lip", "Srp", "Kol", "Ruj", "Lis", "Stu", "Pro"],
        today: "Danas",
        clear: "Očisti",
        format: "dd.mm.yyyy.",
        titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
        weekStart: 1
      };
      $('#datepicker').datepicker({
        language: 'hr'
      });
      
      // Overlay search
      $(document).on('click', '.navbar-search', function(e) {
        e.stopPropagation();
        e.preventDefault();

        var $overlay = $('.header-search-overlay');

        if($overlay.length) {
          $overlay.animateOpacity({
            removeClass: 'd-none'
          });
          $($overlay).find('.form-control').focus();
        }
      });
      $('body').on('mousedown', function(e) {
        var target = $(e.target);
        var overlay = '.header-search-overlay';

        if($(overlay).length && !target.is(overlay) && !target.closest(overlay).length) {
          $(overlay).animateOpacity({
            fadeTo: 0,
            addClass: 'd-none'
          });
        }
      });
      $(document).on('click', '.header-search-overlay .close-header-search', function(e) {
        e.preventDefault();
        var overlay = '.header-search-overlay';
        $(overlay).animateOpacity({
          fadeTo: 0,
          addClass: 'd-none'
        });
      });

      // Scroll to links
      $(document).on('click', 'a[href*=\\#]:not([href=\\#])', function(e) {
        e.preventDefault();
        var target = $(this).attr('href');
        var offset = 0;
        if ($(this).attr('data-offset') && $(this).attr('data-offset') !== "") {
          offset = $(this).attr('data-offset');
        }
        scrollToTarget(target, offset);
      });
      // Disabled links
      $(document).on('click', 'a.disabled', function(e) { e.preventDefault(); return false; });

      // Show/hide back to top
  		$(window).bind('scroll', function(e){
  			if($(window).scrollTop() > 123) $('.scrollTop').animateOpacity({
          removeClass: 'd-none'
        });
  	    else if(!$('.scrollTop').hasClass('d-none')) $('.scrollTop').animateOpacity({
          fadeTo: 0,
          addClass: 'd-none'
        });
      });
      
      // simpleLightbox
      var lb_options = {
        captionsData: 'alt',
        loop : false,
      }
      var lightbox = $('.wp-block-gallery').each(function() {
        $(this).find('a').simpleLightbox(lb_options);
      });

    });

    // Fade animation
    $.fn.extend({
  	   animateNav: function(settings) {
          var options = $.extend({
            child: '.dropdown-menu',
            delay: 100,
            top: 0,
            fade: 'in',
            duration: 200
          }, settings);
          if(options.fade == 'in') {
            if($(this).hasClass('dropdown-submenu')) $(this).children().siblings(options.child).stop()
              .css({display: 'block', top: (0 + options.top)+'%', opacity: 0})
              .delay(options.delay)
              .animate({
                top: '0',
                opacity: 1
              }, options.duration);
            else $(this).children().siblings(options.child).stop()
              .css({display: 'block', top: (100 + options.top)+'%', opacity: 0})
              .delay(options.delay)
              .animate({
                top: '100%',
                opacity: 1
              }, options.duration);
          } else $(this).children().siblings(options.child).stop()
              //.delay(options.delay)
              .fadeOut(options.duration);
      }
  	});
    // Opacity animation
    $.fn.extend({
  	   animateOpacity: function(settings) {
          var options = $.extend({
            fadeTo: 1,
            duration: 300,
            easing: 'swing',
            removeClass: '',
            addClass: ''
          }, settings);
          $(this).stop()
            .removeClass(options.removeClass)
            .fadeTo(options.duration, options.fadeTo, function() {
              if(!options.fadeTo) $(this).addClass(options.addClass);
            });
  	   }
  	});

    // Scroll to function
    function scrollToTarget(e, offset) {
      $('html, body').stop().animate({
        scrollTop: $(e).offset().top + parseInt(offset)
      }, 600, 'swing');
      return false;
    }

})(jQuery);

// Import our SCSS files so webpack can do it's magic (sass to css, minification etc)
import '../scss/style.scss';

// Example importing a HTML designer layout helper
import { debugLayout } from './debugTools.js';
debugLayout(); // Execute it

// Bootstrap
// import 'bootstrap';
// Modernizr
// import './modernizr.min.js';
// Lightbox
// import './simple-lightbox.min.js';
// Simple import
import './main.js';












// Example on how to import ass exported functions
// import * as someScripts from './scripts.js';
// Use imported stuff!
// someScripts.sayHi();
// someScripts.helloWorld();

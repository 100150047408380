import 'jquery';

/**
 * DEVELOPMENT
 * OUTLINE HELPER (+CSS)
 */

export function debugLayout() {
    $("body").on("keydown", function (e) {
        if (e.which === 120) { // F9
            $(this).toggleClass("debugLayout");
            localStorage.outline ^= 1;
        }
    });
    if (localStorage.outline === "1") {
        $("body").addClass("debugLayout");
    }
}

